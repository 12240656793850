<template>
    <div>
        <div class="row no-gutters">
            <div class="col">
                <el-button type="primary" @click="showLandDialog(null)">添加</el-button>
            </div>
        </div>
        <el-table :data="lands" class="mt-2">
            <el-table-column prop="code" label="编号" sortable width="160" />
            <el-table-column prop="name" label="名称" sortable />
            <el-table-column prop="typeName" label="类型" sortable width="100" />
            <el-table-column prop="area" label="面积" sortable width="100" />
            <el-table-column prop="isMappingCompletedName" label="测绘状态" sortable width="100" />
            <el-table-column label="操作" fixed="right" width="100">
                <template #default="scope">
                    <el-button type="text" @click="showLandDialog(scope.row)">修改</el-button>
                    <el-button type="text" class="text-danger" @click="deleteLand(scope.row.id)">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-alert title="土地划分注意事项" description="不可再分割；不同种植户不能种植同一块土地；不同农作物不能同时生长再同一个块土地" type="warning" show-icon
            :closable="false" class="mt-2" />
        <el-alert title="土地划分建议" description="XX村1号井3条田4号地" type="success" show-icon :closable="false" class="mt-2" />

        <el-dialog :visible.sync="landDialogVisible" title="土地" :close-on-click-modal="false" append-to-body
            width="800px">
            <el-form>
                <div class="text-center">
                    <file-uploader v-model="land.photoUrl" accept="image/*" :data="{width:1280}" class="d-inline-block">
                        <template #default="scope">
                            <el-image :src="'/'+scope.file" fit="cover" class="border rounded"
                                style="width: 400px; height: 240px;" />
                        </template>
                    </file-uploader>
                </div>

                <el-row :gutter="8" class="mt-3">
                    <el-col :span="12">
                        <el-form-item label="编号" class="form-item-required">
                            <el-input v-model="land.code" placeholder="编号" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="名称" class="form-item-required">
                            <el-input v-model="land.name" placeholder="名称" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="8">
                    <el-col :span="12">
                        <el-form-item label="类型" class="form-item-required">
                            <el-select v-model="land.type" placeholder="类型">
                                <el-option v-for="landType in $store.state.dataDefinitions.landTypes"
                                    :key="landType.key" :value="landType.key" :label="landType.value" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="面积（亩）" class="form-item-required">
                            <el-input-number v-model="land.area" controls-position="right" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="8">
                    <el-col :span="12">
                        <el-form-item label="口粮地面积（亩）" class="form-item-required">
                            <el-input-number v-model="land.rationArea" controls-position="right" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="地图默认缩放级别" class="form-item-required">
                            <el-input-number v-model="land.defaultZoom" controls-position="right" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="saveLand">确定</el-button>
                <el-button @click="landDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        props: {
            landContractId: { type: String },
        },
        data() {
            return {
                lands: [],
                land: {},
                landDialogVisible: false,
            };
        },
        methods: {
            async loadLands() {
                let response = await this.$axios.get('/api/Land/GetLands', { params: { landContractId: this.landContractId } });
                this.lands = response.data;
            },
            showLandDialog(land) {
                if (land) {
                    this.land = JSON.parse(JSON.stringify(land));
                }
                else {
                    this.land = {
                        landContractId: this.landContractId,
                        area: 0,
                        defaultZoom: 16,
                        rationArea: 0,
                    };
                }
                this.landDialogVisible = true;
            },
            async saveLand() {
                if (this.land.id == undefined) {
                    await this.$axios.post('/api/Land/AddLand', {
                        landContractId: this.land.landContractId,
                        code: this.land.code,
                        name: this.land.name,
                        type: this.land.type,
                        area: this.land.area,
                        rationArea: this.land.rationArea,
                        defaultZoom: this.land.defaultZoom,
                        photoUrl: this.land.photoUrl,
                    });
                    this.landDialogVisible = false;
                    this.loadLands();
                }
                else {
                    await this.$axios.post('/api/Land/UpdateLand', {
                        id: this.land.id,
                        code: this.land.code,
                        name: this.land.name,
                        type: this.land.type,
                        area: this.land.area,
                        rationArea: this.land.rationArea,
                        defaultZoom: this.land.defaultZoom,
                        photoUrl: this.land.photoUrl,
                    });
                    this.landDialogVisible = false;
                    this.loadLands();
                }
            },
            async deleteLand(id) {
                if (!confirm('确定要删除吗？')) {
                    return;
                }

                await this.$axios.post('/api/Land/DeleteLand', { id: id });
                this.loadLands();
            },
        },
        created() {
            this.loadLands();
        },
    };
</script>